import { Box, TypographyTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { TMTooltip, TooltipPaper, Typography, TypographyProps } from '@talentmesh/core';
import React, { useCallback, useState } from 'react';

interface TooltipTypographyProps extends React.PropsWithoutRef<TypographyProps> {}

const TooltipTypography: OverridableComponent<TypographyTypeMap> = ({ children, ...props }: TooltipTypographyProps) => {
    const [open, setOpen] = useState(false);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const textRef = useCallback(
        (node: HTMLSpanElement) => setIsOverflowing(node?.scrollWidth > node?.clientWidth),
        [setIsOverflowing],
    );

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const handleOpen = useCallback(() => {
        // remove to enable the tooltip feature
        // setOpen(isOverflowing);
    }, [isOverflowing, setOpen]);

    return (
        <Box width="100%" sx={{ '& > span': { width: '100%' } }}>
            <TMTooltip
                open={open}
                onOpen={handleOpen}
                onClose={handleClose}
                title={
                    <TooltipPaper>
                        <Typography variant="body2" color="secondary">
                            {children}
                        </Typography>
                    </TooltipPaper>
                }
            >
                <Box maxWidth="100%">
                    <Typography variant="body2" textOverflow="ellipsis" overflow="hidden" {...props} ref={textRef}>
                        {children}
                    </Typography>
                </Box>
            </TMTooltip>
        </Box>
    );
};

export default TooltipTypography;
