import { dividerClasses, useTheme } from '@mui/material';
import { LinearProgress, Typography } from '@talentmesh/core';
import { MRT_Row, MRT_TableInstance, MRT_TableState, useMaterialReactTable } from 'material-react-table';
import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { ApplicantRecruitmentOverviewResponse, CandidateTabs } from '../../../../../Models/CandidateOverview';
import UIStrings from '../../../../../Utils/UIStrings';
import canSelectCandidate from '../../Utils/CanSelectCandidate';
import { CandidatesOverviewContextType } from '../CandidatesOverviewContext';
import columns from './utils/columns';
import useManualSorting from './utils/useManualSorting';

type MRTStateMap = Map<string, MRT_TableState<ApplicantRecruitmentOverviewResponse>>;

export const CandidatesTableContext = createContext<
    MRT_TableInstance<ApplicantRecruitmentOverviewResponse> | undefined
>(undefined);

export const useCandidatesTable = ({
    assessmentId,
    candidates,
    totalCandidatesCount,
    loading,
    tabValue,
    sort,
}: CandidatesOverviewContextType) => {
    const theme = useTheme();
    const mrtStateKey = 'mrtState';
    const { onSortingChange, sorting } = useManualSorting(sort);

    const enableRowSelection = useCallback(
        (row: MRT_Row<ApplicantRecruitmentOverviewResponse>) =>
            tabValue === CandidateTabs.Open && canSelectCandidate(row.original),
        // change only after loading, otherwise table is too fast
        [loading],
    );

    const initialState = useMemo<MRT_TableState<ApplicantRecruitmentOverviewResponse> | undefined>(() => {
        const mrtStateStr = localStorage.getItem(mrtStateKey);

        if (mrtStateStr) {
            const mrtStateMap: MRTStateMap = new Map(JSON.parse(mrtStateStr));
            return mrtStateMap.get(assessmentId);
        } else {
            return undefined;
        }
    }, []);

    const table = useMaterialReactTable({
        columns,
        data: candidates,
        enableColumnActions: false,
        enableTopToolbar: false,
        enableBottomToolbar: false,
        enableColumnOrdering: true,
        enableColumnPinning: true,
        enableColumnResizing: true,
        enableRowSelection,
        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,
        autoResetPageIndex: true,
        rowCount: totalCandidatesCount,
        state: { density: 'compact', isLoading: loading, sorting },
        onSortingChange,
        initialState,
        displayColumnDefOptions: { 'mrt-row-select': { size: 52, enableHiding: false } },
        renderEmptyRowsFallback: () => (
            <Typography align="center" alignSelf="center" maxWidth="min(100vw, 1200px)">
                {UIStrings.NoRows}
            </Typography>
        ),
        muiSelectAllCheckboxProps: { disabled: tabValue !== CandidateTabs.Open },
        muiCircularProgressProps: { Component: <LinearProgress sx={{ width: '100%', placeSelf: 'start' }} /> },
        muiTableBodyRowProps: {
            sx: { '&:hover': { 'td:after': { backgroundColor: theme.palette.background.silverSand } } },
        },
        muiTablePaperProps: { elevation: 0, sx: { borderRadius: 0 } },
        muiTopToolbarProps: {
            sx: { backgroundColor: theme.palette.grey[100], borderBottom: `solid 1px ${theme.palette.border.main}` },
        },
        muiTableHeadCellProps: {
            sx: {
                border: `1px ${theme.palette.border.main}`,
                borderStyle: 'solid',
                backgroundColor: theme.palette.grey[100],
                color: theme.palette.text.secondary,
                whiteSpace: 'nowrap',
                // this is a shadow box to separate the pinned columns
                '&[data-pinned="true"]:before': { boxShadow: 'none' },
                '.Mui-TableHeadCell-Content': {
                    justifyContent: 'space-between',
                    '.Mui-TableHeadCell-Content-Wrapper': { whiteSpace: 'nowrap' },
                },
                '.Mui-TableHeadCell-ResizeHandle-Wrapper': {
                    position: 'absolute',
                    right: -2,
                    [`& .${dividerClasses.root}`]: { visibility: 'hidden' },
                },
            },
        },
        muiTableBodyCellProps: {
            sx: {
                border: `1px ${theme.palette.border.main}`,
                borderStyle: 'none solid none solid',
                // this is a shadow box to separate the pinned columns
                '&[data-pinned="true"]:before': { boxShadow: 'none' },
            },
        },
    });

    useEffect(() => {
        return () => {
            let mrtStateMap: MRTStateMap = new Map();
            const mrtStateStr = localStorage.getItem(mrtStateKey);

            if (mrtStateStr) {
                mrtStateMap = new Map(JSON.parse(mrtStateStr));
            }

            mrtStateMap.set(assessmentId, table.getState());

            localStorage.setItem(mrtStateKey, JSON.stringify(Array.from(mrtStateMap.entries())));
        };
    }, []);

    return table;
};

export const useCandidatesTableContext = () => {
    const context = useContext(CandidatesTableContext);
    if (!context) {
        throw new Error('useCandidatesTableContext must be used within its provider');
    }
    return context;
};
