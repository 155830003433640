import { useTheme } from '@mui/material';
import { MenuButton, MenuItemWithValueProps } from '@talentmesh/core';
import React, { useMemo } from 'react';
import { CellProps } from '.';
import { AssessmentStatuses } from '../../../../../../Models/AssessmentData';
import ATSAction from '../../../../../../Models/ATSAction';
import isATSActionAllowedInState from '../../../../../../Utils/ATSActions/isATSActionAllowedInState';
import UIStrings from '../../../../../../Utils/UIStrings';
import { DialogEnum, useActionDialogContext } from '../../../../../Dialogs/ATS/Context/ActionDialogContext';
import { statusToString } from '../../../../Utils/Utils';
import { useCandidatesOverviewContext } from '../../CandidatesOverviewContext';
import { mapFromCandidateOverviewToCandidateDialogModel } from '../../../../../Dialogs/Dialogs.Utils';

const HiringActionCell = ({ candidate }: CellProps) => {
    const theme = useTheme();
    const { assessmentId, assessmentStatus, testTypes, includesExperienceCriteria } = useCandidatesOverviewContext();
    const { showDialog } = useActionDialogContext();
    const status = candidate.applicantStatus;

    const menuItems = useMemo<MenuItemWithValueProps[]>(() => {
        const getDisabledByAction = (action: ATSAction) =>
            assessmentStatus === AssessmentStatuses.Closed || !isATSActionAllowedInState(action, status);
        const handleOnClick = (action: DialogEnum) => () => {
            showDialog(
                [mapFromCandidateOverviewToCandidateDialogModel(assessmentId, candidate)],
                action,
                testTypes,
                includesExperienceCriteria,
            );
        };

        return [
            {
                menuItemLabel: UIStrings.Hire,
                disabled: getDisabledByAction(ATSAction.Hire),
                onClick: handleOnClick(DialogEnum.Hire),
            },
            {
                menuItemLabel: UIStrings.InviteForInterview,
                disabled: getDisabledByAction(ATSAction.InviteForInterview),
                onClick: handleOnClick(DialogEnum.InviteForInterview),
            },
            {
                menuItemLabel: UIStrings.Reject,
                disabled: getDisabledByAction(ATSAction.Reject),
                onClick: handleOnClick(DialogEnum.Reject),
            },
        ];
    }, [status]);

    return (
        <MenuButton
            label={statusToString(status)}
            menuItems={menuItems}
            buttonProps={{
                fullWidth: true,
                disableRipple: true,
                sx: {
                    padding: 0,
                    placeContent: 'space-between',
                    fontWeight: theme.typography.fontWeightRegular,
                    textAlign: 'start',
                },
            }}
        />
    );
};

export default HiringActionCell;
