import React from 'react';
import { MenuIconButton, MenuItemWithValueProps } from '@talentmesh/core';
import {
    DoneIcon,
    ClearIcon,
    DoneAllIcon,
    MoreVertIcon,
    ResendInvitationIcon,
    RestartAltIcon,
} from '@talentmesh/icons';
import ATSAction from '../../../../../Models/ATSAction';
import isATSActionAllowedInState from '../../../../../Utils/ATSActions/isATSActionAllowedInState';
import isResendInvitationAllowed from '../../../../../Utils/isResendInvitationAllowed';
import isResetAssessmentAllowedState from '../../../../../Utils/isResetAssessmentAllowedState';
import UIStrings from '../../../../../Utils/UIStrings';
import { DialogEnum, useActionDialogContext } from '../../../../Dialogs/ATS/Context/ActionDialogContext';
import { useCandidatesResultsContext } from '../../Contexts/CandidateResultsContext';
import { mapFromCandidateAssessmentResultsToCandidateDialogModel } from '../../../../Dialogs/Dialogs.Utils';

interface HeaderActionsProps {
    setOpenAssessmentResetDialog: (value: boolean) => void;
}

function HeaderActions({ setOpenAssessmentResetDialog }: HeaderActionsProps): JSX.Element {
    const { showDialog, resendInvitationAsync } = useActionDialogContext();
    const { results, candidateId, assessmentId } = useCandidatesResultsContext();
    const { completedAt, candidateStatus, candidateFirstName, candidateLastName } = results;

    const resendInvitationHandlerAsync = async () => {
        await resendInvitationAsync(assessmentId, candidateId, candidateFirstName, candidateLastName);
    };

    const resetAssessmentHandlerAsync = () => {
        setOpenAssessmentResetDialog(true);
    };

    const atsActions: MenuItemWithValueProps[] = [
        {
            icon: <ClearIcon color="secondary" />,
            menuItemLabel: UIStrings.Reject,
            onClick: () => {
                showDialog(
                    [mapFromCandidateAssessmentResultsToCandidateDialogModel(results)],
                    DialogEnum.Reject,
                    results.includedTests,
                    results.includesExperienceCriteria,
                );
            },
            dense: true,
            disabled: !isATSActionAllowedInState(ATSAction.Reject, candidateStatus),
        },
        {
            icon: <DoneIcon color="secondary" />,
            menuItemLabel: UIStrings.MarkAsInviteForInterview,
            onClick: () => {
                showDialog(
                    [mapFromCandidateAssessmentResultsToCandidateDialogModel(results)],
                    DialogEnum.InviteForInterview,
                    results.includedTests,
                    results.includesExperienceCriteria,
                );
            },
            dense: true,
            disabled: !isATSActionAllowedInState(ATSAction.InviteForInterview, candidateStatus),
        },
        {
            icon: <DoneAllIcon color="secondary" />,
            menuItemLabel: UIStrings.MarkAsHired,
            onClick: () => {
                showDialog([mapFromCandidateAssessmentResultsToCandidateDialogModel(results)], DialogEnum.Hire);
            },
            dense: true,
            disabled: !isATSActionAllowedInState(ATSAction.Hire, candidateStatus),
        },
    ];

    const availableToAllAtsActions = [
        {
            menuItemLabel: UIStrings.ResendInvitation,
            icon: <ResendInvitationIcon color="secondary" />,
            onClick: resendInvitationHandlerAsync,
            dense: true,
            disabled: !isResendInvitationAllowed(candidateStatus, completedAt),
        },
        {
            menuItemLabel: UIStrings.ResetAssessment,
            icon: <RestartAltIcon color="secondary" />,
            onClick: resetAssessmentHandlerAsync,
            dense: true,
            disabled: results.isReset || !isResetAssessmentAllowedState(candidateStatus),
        },
    ];

    const resultsMoreMenuOptions: MenuItemWithValueProps[] = [];

    if (!results.isInternal) {
        resultsMoreMenuOptions.push(...atsActions);
    }

    resultsMoreMenuOptions.push(...availableToAllAtsActions);

    return <MenuIconButton menuItems={resultsMoreMenuOptions} icon={<MoreVertIcon />} />;
}

export default HeaderActions;
