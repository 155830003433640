import { useTheme } from '@mui/material';
import React, { SyntheticEvent } from 'react';
import { Box, Stack, Typography } from '@talentmesh/core';
import { generatePath, useLocation } from 'react-router';
import AttractApplicantsButton from '../../../../../Components/AttractApplicantsButton';
import { AssessmentStatuses } from '../../../../../Models/AssessmentData';
import UIStrings from '../../../../../Utils/UIStrings';
import RecruitmentMenuButton from '../RecruitmentMenuButton';
import { RecruitmentOverview } from '../../../../../Models/RecruitmentOverview';
import RoutePath from '../../../../../Routing/RoutePath';
import useTalentMeshHistory from '../../../../../Routing/useTalentMeshHistory';
import useCompanyId from '../../../../../Hooks/UseCompanyId';

export interface RecruitmentListItemHeaderProps {
    recruitment: RecruitmentOverview;
}

export const RecruitmentListItemHeader = ({ recruitment }: RecruitmentListItemHeaderProps): JSX.Element => {
    const theme = useTheme();
    const history = useTalentMeshHistory();
    const location = useLocation();
    const companyId = useCompanyId();
    const { name, status, id, jobLocation, workArrangement, jobAdId, jobDescriptionLanguage } = recruitment;

    const addCandidatesHandler = (e: SyntheticEvent) => {
        // prevent propagation so that RecruitmentListItemActionArea does not fire
        e.stopPropagation();
        e.preventDefault();
        const path = generatePath(jobAdId ? RoutePath.AttractApplicantsJobBoards : RoutePath.AttractApplicantsInvite, {
            companyId,
            recruitmentId: recruitment.id,
        });
        history.push(path, { from: location.pathname }); // wee need to preserve the location from which user has come
    };

    return (
        <Stack direction="row">
            <Stack direction="column">
                <Typography variant="h4" color={theme.palette.text.menuTitle}>
                    {name}
                </Typography>
                {(jobLocation || workArrangement === 'Remote') && (
                    <Typography variant="body1" color={theme.palette.text.secondary}>
                        {workArrangement === 'Remote' ? UIStrings.FullyRemote : jobLocation}
                    </Typography>
                )}
            </Stack>
            <Box sx={{ flexGrow: 1 }} />
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <AttractApplicantsButton
                    disabled={status === AssessmentStatuses.Closed}
                    onClick={addCandidatesHandler}
                    data-cy="attractApplicants"
                />
                <RecruitmentMenuButton
                    id={id}
                    name={name}
                    status={status}
                    jobAdId={jobAdId}
                    jobDescriptionLanguage={jobDescriptionLanguage}
                />
            </Stack>
        </Stack>
    );
};
