import { useTheme } from '@mui/material/styles';
import React, { useCallback, useEffect } from 'react';
import { Stack, Box, Button, Search, LoadingButton } from '@talentmesh/core';
import UIStrings from '../../../../../Utils/UIStrings';
import { DialogEnum, useActionDialogContext } from '../../../../Dialogs/ATS/Context/ActionDialogContext';
import { mapFromCandidateOverviewToCandidateDialogModel } from '../../../../Dialogs/Dialogs.Utils';
import { useCandidatesOverviewContext } from '../../Contexts/CandidatesOverviewContext';
import AdvancedFiltersToolbar from '../List/AdvancedFiltersToolbar';
import CandidatesListToolTip from '../List/CandidatesListToolTip';
import ToolbarFilterButton from '../List/ToolbarFilterButton';
import { useCandidatesTableContext } from '../../Contexts/CandidatesTableContext/CandidatesTableContext';

const TableFilters = () => {
    const theme = useTheme();
    const { showDialog } = useActionDialogContext();
    const {
        assessmentId,
        selected,
        setSelected,
        testTypes,
        tabValue,
        searchByName,
        openFilter,
        search,
        includesExperienceCriteria,
        groupReportProcessing,
        getApplicantGroupReportResults,
    } = useCandidatesOverviewContext();
    const table = useCandidatesTableContext();

    const selectedMrtRows = table.getSelectedRowModel().rows;
    useEffect(() => {
        setSelected(selectedMrtRows.map((row) => row.original));
    }, [selectedMrtRows]);

    const rejectDialogHandle = useCallback(() => {
        showDialog(
            selected.map((candidate) => mapFromCandidateOverviewToCandidateDialogModel(assessmentId, candidate)),
            DialogEnum.Reject,
            testTypes,
            includesExperienceCriteria,
        );
        table.resetRowSelection();
    }, [selected]);

    const onSearch = (query: string) => searchByName(query);

    const handleGroupReport = useCallback(async () => {
        await getApplicantGroupReportResults();
        table.resetRowSelection();
    }, [selected]);

    return (
        <Stack
            direction="row"
            sx={{
                paddingInline: 1,
                cursor: 'auto',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: theme.spacing(8),
                backgroundColor: theme.palette.grey[100],
                border: `1px solid ${theme.palette.border.main}`,
            }}
            data-cy="gridToolbar"
        >
            <Box>
                {selected.length > 0 && (
                    <Stack direction="row" spacing={1.5}>
                        <CandidatesListToolTip title={UIStrings.RejectApplicants}>
                            <Button
                                color="error"
                                variant="contained"
                                size="small"
                                onClick={rejectDialogHandle}
                                data-cy="ToolbarGroupRemove"
                            >
                                {UIStrings.RejectByCount(selected.length)}
                            </Button>
                        </CandidatesListToolTip>
                        <LoadingButton
                            variant="contained"
                            size="small"
                            loading={groupReportProcessing}
                            onClick={handleGroupReport}
                            sx={{ backgroundColor: theme.palette.common.white, color: theme.palette.primary.main }}
                            data-cy="ToolbarApplicantGroup"
                        >
                            {UIStrings.CreateApplicantsPresentation}
                        </LoadingButton>
                    </Stack>
                )}
            </Box>
            <Stack direction="row" spacing={1.25}>
                {openFilter && <AdvancedFiltersToolbar />}
                <Search
                    value={search}
                    data-cy="ToolbarSearch"
                    placeholder={UIStrings.FilterByApplicantsName}
                    onSearch={onSearch}
                    InputProps={{ sx: { maxHeight: theme.spacing(5) } }}
                />
                {tabValue === 'Open' && <ToolbarFilterButton />}
            </Stack>
        </Stack>
    );
};

export default TableFilters;
