import { useTheme } from '@mui/material';
import { Stack } from '@talentmesh/core';
import React from 'react';
import { generatePath, useLocation } from 'react-router';
import AttractApplicantsButton from '../../../../../Components/AttractApplicantsButton';
import useCompanyId from '../../../../../Hooks/UseCompanyId';
import { AssessmentStatuses } from '../../../../../Models/AssessmentData';
import RoutePath from '../../../../../Routing/RoutePath';
import useTalentMeshHistory from '../../../../../Routing/useTalentMeshHistory';
import { useCandidatesOverviewContext } from '../../Contexts/CandidatesOverviewContext';
import EditColumnsButton from './EditColumnsButton';

function CandidatesOverviewHeaderContentToolbar(): JSX.Element {
    const theme = useTheme();
    const history = useTalentMeshHistory();
    const location = useLocation();
    const { assessmentId, assessmentStatus } = useCandidatesOverviewContext();
    const companyId = useCompanyId();

    const addApplicantsHandler = () => {
        const path = generatePath(RoutePath.AttractApplicantsJobBoards, { companyId, recruitmentId: assessmentId });
        history.push(path, { from: location.pathname });
    };

    return (
        <Stack
            data-cy="ApplicantsOverviewHeaderContentToolbar"
            sx={{ alignItems: 'center', height: theme.spacing(7.5) }}
            direction="row"
            spacing={1.25}
        >
            <EditColumnsButton />
            <AttractApplicantsButton
                disabled={assessmentStatus === AssessmentStatuses.Closed}
                onClick={addApplicantsHandler}
                data-cy="AttractApplicantsButton"
            />
        </Stack>
    );
}

export default CandidatesOverviewHeaderContentToolbar;
