/* eslint-disable import/prefer-default-export */
import UIStrings from '../../../../Utils/UIStrings';
import toDateString from '../../../../Utils/DateHelper';
import { CandidateAssessmentResults } from '../../../../Models/CandidateAssessmentResults';
import { ApplicantStatus } from '../../../../Models/CandidateOverview';
import { TestTypes } from '../../../../Models/Configuration';

export enum ResultTabType {
    Skills = 'Skills',
    Aptitude = 'Aptitude',
    Personality = 'Personality',
    EmotionalIntelligence = 'EmotionalIntelligence',
    Experience = 'Experience',
    ApplicantInformation = 'ApplicantInformation',
    Note = 'Note',
    None = 'None',
}

function getTabHeaderByTestType(testType: TestTypes): string {
    switch (testType) {
        case TestTypes.Aptitude:
            return UIStrings.Aptitude;
        case TestTypes.EmotionalIntelligence:
            return UIStrings.EmotionalIntelligence;
        case TestTypes.Personality:
            return UIStrings.Personality;
        case TestTypes.Skills:
            return UIStrings.Skills;
        default:
            return '';
    }
}

function getCandidateResultsTabValue(resultTabType: ResultTabType): string {
    switch (resultTabType) {
        case ResultTabType.Personality:
            return '1';
        case ResultTabType.Skills:
            return '2';
        case ResultTabType.Aptitude:
            return '3';
        case ResultTabType.EmotionalIntelligence:
            return '4';
        case ResultTabType.Experience:
            return '5';
        case ResultTabType.ApplicantInformation:
            return '6';
        case ResultTabType.Note:
            return '7';
        case ResultTabType.None:
        default:
            return '';
    }
}

function getFirstTest(result: CandidateAssessmentResults): ResultTabType {
    if (result.personalityScore != null) {
        return ResultTabType.Personality;
    }

    if (result.aptitudeScore != null) {
        return ResultTabType.Aptitude;
    }

    if (result.skillsScore != null) {
        return ResultTabType.Skills;
    }

    if (result.emotionalIntelligenceScore != null) {
        return ResultTabType.EmotionalIntelligence;
    }

    return ResultTabType.ApplicantInformation;
}

function getSnapshotCounterString(current: number, total: number): string {
    return `${UIStrings.Snapshot} ${current} ${UIStrings.Of} ${total}`;
}

function getSnapshotDateString(date: Date): string {
    const time = date.toLocaleTimeString('default', {
        hour: '2-digit',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
    });
    const calendarDate = toDateString(date);
    return `${calendarDate} • ${time}`;
}

function getResultsHeaderByTestType(testType: ResultTabType): string {
    switch (testType) {
        case ResultTabType.Personality:
            return UIStrings.PersonalityTestResultsFor;
        case ResultTabType.Aptitude:
            return UIStrings.AptitudeTestResultsFor;
        case ResultTabType.Skills:
            return UIStrings.SkillsTestResultsFor;
        case ResultTabType.EmotionalIntelligence:
            return UIStrings.EmotionalIntelligenceTestResultsFor;
        default:
            throw new Error(`Test type ${testType} not supported`);
    }
}

function mapTestTypeToResultTabType(testType: TestTypes): ResultTabType {
    switch (testType) {
        case TestTypes.Personality:
            return ResultTabType.Personality;
        case TestTypes.Aptitude:
            return ResultTabType.Aptitude;
        case TestTypes.Skills:
            return ResultTabType.Skills;
        case TestTypes.EmotionalIntelligence:
            return ResultTabType.EmotionalIntelligence;
        default:
            return ResultTabType.None;
    }
}

const isTestCompleted = (results: CandidateAssessmentResults, testType: TestTypes): boolean => {
    switch (testType) {
        case TestTypes.Personality:
            return results.personalityScore != null;
        case TestTypes.Aptitude:
            return results.aptitudeScore != null;
        case TestTypes.Skills:
            return results.skillsScore != null;
        case TestTypes.EmotionalIntelligence:
            return results.emotionalIntelligenceScore != null;
        default:
            return false;
    }
};

const hasCandidateOnboarded = (candidateStatus: ApplicantStatus) => {
    return candidateStatus !== ApplicantStatus.Invited;
};

export {
    getTabHeaderByTestType,
    getCandidateResultsTabValue,
    getSnapshotCounterString,
    getSnapshotDateString,
    getResultsHeaderByTestType,
    getFirstTest,
    mapTestTypeToResultTabType,
    isTestCompleted,
    hasCandidateOnboarded,
};
