import { DirectReportsType, WorkExperienceType } from '@talentmesh/core';
import { TestTypes } from '../../Models/Configuration';
import { AssessmentStatuses } from '../../Models/AssessmentData';

export interface RecruitmentSpecificationDTO {
    name: string;
    locationId?: string;
    jobLocation?: string;
    clientId: string;
    jobFunctionId: number;
    assessmentId: string;
    workExperience: WorkExperienceType;
    includedTests: TestTypes[];
    directReports: DirectReportsType;
    assessmentStatus: AssessmentStatuses;
    includesExperienceCriteria: boolean;
}

export const DefaultRecruitmentSpecificationDTO: RecruitmentSpecificationDTO = {
    name: '',
    locationId: undefined,
    jobLocation: undefined,
    clientId: '',
    jobFunctionId: 0,
    assessmentId: '',
    workExperience: 'EntryLevel',
    includedTests: [],
    directReports: 'None',
    assessmentStatus: AssessmentStatuses.NonPublic,
    includesExperienceCriteria: false,
};
