import { MRT_ColumnDef } from 'material-react-table';
import React from 'react';
import {
    ApplicantRecruitmentOverviewResponse,
    PercentileScoreDTO,
    ScoreDTO,
} from '../../../../../../Models/CandidateOverview';
import { TalentScore } from '../../../../../../Models/TalentScore';
import toDateString from '../../../../../../Utils/DateHelper';
import UIStrings from '../../../../../../Utils/UIStrings';
import { ApplicantCell, FavoriteCell, HiringActionCell, MoreActionsCell, ScoreCell, TextCell } from '../cells';

type CandidateCol = MRT_ColumnDef<ApplicantRecruitmentOverviewResponse>;
type CellParams = Parameters<Required<CandidateCol>['Cell']>['0'];

// todo! currently missing: phone, notice period, work permit (US), willing to relocate, years of exp, expected salary
// applied (date), assessment ongoing (date), invited for interview (date), interview (date), hired (date
// rejected (date), hiring actions dropdown

const columns: CandidateCol[] = [
    {
        accessorKey: 'isFavorite',
        header: UIStrings.Favorite,
        Cell: ({ row }: CellParams) => <FavoriteCell candidate={row.original} />,
        size: 102,
        enableResizing: false,
        enableSorting: false,
    },
    {
        id: 'applicant',
        header: UIStrings.Applicant,
        accessorFn: (row) => `${row.candidateFirstName} ${row.candidateLastName}`,
        Cell: ({ row, renderedCellValue }: CellParams) => (
            <ApplicantCell id={row.original.candidateId}>{renderedCellValue}</ApplicantCell>
        ),
        enableSorting: false,
    },
    {
        id: 'applicantAction',
        header: 'Action',
        Cell: ({ row }: CellParams) => <MoreActionsCell candidate={row.original} />,
        size: 40,
        enableResizing: false,
        enableSorting: false,
    },
    {
        accessorKey: 'candidateEmail',
        header: UIStrings.Email,
        size: 300,
        enableSorting: false,
        Cell: ({ renderedCellValue }: CellParams) => <TextCell value={renderedCellValue} />,
    },
    {
        accessorKey: 'locationDetails.description',
        header: UIStrings.CityAndCountry,
        enableSorting: false,
        Cell: ({ renderedCellValue }: CellParams) => <TextCell value={renderedCellValue} />,
    },
    {
        accessorKey: 'experience.title',
        header: UIStrings.LatestJobExperience,
        enableSorting: false,
        Cell: ({ renderedCellValue }: CellParams) => <TextCell value={renderedCellValue} />,
    },
    {
        header: UIStrings.HighestLevelOfEducation,
        accessorFn: ({ education }) => {
            if (!education) {
                return null;
            }

            const { educationLevel } = education;
            if (educationLevel.level === 'UserDefined') {
                return educationLevel.userDefined;
            } else {
                return UIStrings[educationLevel.level!];
            }
        },
        enableSorting: false,
        Cell: ({ renderedCellValue }: CellParams) => <TextCell value={renderedCellValue} />,
    },
    {
        header: UIStrings.ApplicantStatus,
        accessorFn: ({ applicantStatus }) => UIStrings[applicantStatus],
        enableSorting: false,
        Cell: ({ renderedCellValue }: CellParams) => <TextCell value={renderedCellValue} />,
    },
    {
        header: UIStrings.Invited,
        accessorFn: ({ invitedAt }) => (invitedAt ? toDateString(new Date(invitedAt)) : undefined),
        enableSorting: false,
        Cell: ({ renderedCellValue }: CellParams) => <TextCell value={renderedCellValue} />,
    },
    {
        header: UIStrings.AssessmentCompleted,
        accessorFn: ({ completedAt }) => (completedAt ? toDateString(new Date(completedAt)) : undefined),
        enableSorting: false,
        Cell: ({ renderedCellValue }: CellParams) => <TextCell value={renderedCellValue} />,
    },
    {
        id: 'hiringAction',
        header: UIStrings.HiringAction,
        Cell: ({ row }: CellParams) =>
            row.original.isInternal ? (
                <TextCell value={UIStrings.InternalBenchmark} />
            ) : (
                <HiringActionCell candidate={row.original} />
            ),
        minSize: 195,
    },
    {
        accessorKey: 'talentScore',
        header: UIStrings.TalentScore,
        Cell: ({ cell }: CellParams) => <ScoreCell scoring={cell.getValue<TalentScore>()} />,
    },
    {
        accessorKey: 'experienceCriteriaScore',
        header: UIStrings.Experience,
        Cell: ({ cell }: CellParams) => <ScoreCell scoring={cell.getValue<ScoreDTO>()} />,
    },
    {
        accessorKey: 'personalityPercentileScore',
        header: UIStrings.Personality,
        Cell: ({ cell }: CellParams) => <ScoreCell scoring={cell.getValue<PercentileScoreDTO>()} />,
    },
    {
        accessorKey: 'aptitudePercentileScore',
        header: UIStrings.Aptitude,
        Cell: ({ cell }: CellParams) => <ScoreCell scoring={cell.getValue<PercentileScoreDTO>()} />,
    },
    {
        accessorKey: 'skillsPercentileScore',
        header: UIStrings.Skills,
        Cell: ({ cell }: CellParams) => <ScoreCell scoring={cell.getValue<PercentileScoreDTO>()} />,
    },
    {
        accessorKey: 'emotionalIntelligencePercentileScore',
        header: UIStrings.EmotionalIntelligence,
        Cell: ({ cell }: CellParams) => <ScoreCell scoring={cell.getValue<PercentileScoreDTO>()} />,
    },
];

export default columns;
