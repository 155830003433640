import { useTheme } from '@mui/material/styles';
import { Box, Paper } from '@talentmesh/core';
import { MaterialReactTable } from 'material-react-table';
import React, { useEffect } from 'react';
import { useActionDialogContext } from '../../../Dialogs/ATS/Context/ActionDialogContext';
import { useCandidatesOverviewContext } from '../Contexts/CandidatesOverviewContext';
import { useCandidatesTableContext } from '../Contexts/CandidatesTableContext/CandidatesTableContext';
import TableFilters from './CandidatesTable/TableFilters';
import TableNav from './CandidatesTable/TableNav';

function CandidatesOverviewList(): JSX.Element {
    const theme = useTheme();
    const { loadPageAsync } = useCandidatesOverviewContext();
    const { candidateStatuses } = useActionDialogContext();
    const table = useCandidatesTableContext();

    useEffect(() => {
        const doReloadThePage = async () => await loadPageAsync();
        doReloadThePage();
    }, [candidateStatuses]);

    return (
        <Paper elevation={0} sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
            <Box data-cy="candidatesOverviewList" sx={{ backgroundColor: theme.palette.common.white }}>
                <TableFilters />
                <MaterialReactTable table={table} />
                <TableNav />
            </Box>
        </Paper>
    );
}

export default CandidatesOverviewList;
