import React from 'react';
import TooltipTypography from '../../../Components/TooltipTypography';

interface TextCellProps {
    value: React.ReactNode;
}

const TextCell = ({ value }: TextCellProps) => {
    return <TooltipTypography>{value}</TooltipTypography>;
};

export default TextCell;
