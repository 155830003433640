import React from 'react';
import { generatePath } from 'react-router';
import { Card } from '@talentmesh/core';
import { RecruitmentOverview } from '../../../../../Models/RecruitmentOverview';
import RoutePath from '../../../../../Routing/RoutePath';
import RecruitmentListItemActionArea from './RecruitmentListItemActionArea';
import RecruitmentListItemBody from './RecruitmentListItemBody';
import { RecruitmentListItemHeader } from './RecruitmentListItemHeader';
import RecruitmentListItemFooter from './RecruitmentListItemFooter';
import useCompanyId from '../../../../../Hooks/UseCompanyId';

interface RecruitmentListItemProps {
    recruitment: RecruitmentOverview;
}
function RecruitmentListItem({ recruitment }: RecruitmentListItemProps): JSX.Element {
    const companyId = useCompanyId();
    return (
        <Card elevation={3} sx={{ width: '100%' }} data-cy="recruitmentCard">
            <RecruitmentListItemActionArea
                navigateTo={generatePath(RoutePath.CandidatesOverview, { companyId, assessmentId: recruitment.id })}
            >
                <RecruitmentListItemHeader recruitment={recruitment} />
                <RecruitmentListItemBody recruitment={recruitment} />
                <RecruitmentListItemFooter recruitment={recruitment} />
            </RecruitmentListItemActionArea>
        </Card>
    );
}

export default RecruitmentListItem;
