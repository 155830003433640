import { Theme } from '@mui/material';
import UIStrings from '../../../Utils/UIStrings';
import { TestTypes } from '../../../Models/Configuration';
import { ApplicantStatus } from '../../../Models/CandidateOverview';

export function statusToString(status: ApplicantStatus): string {
    return UIStrings[status];
}

export const mainTestTypesRenderingOrder: Array<TestTypes> = [
    TestTypes.Personality,
    TestTypes.Aptitude,
    TestTypes.Skills,
    TestTypes.EmotionalIntelligence,
];
