import { Box, FormHelperText, Stack, Typography, useTheme } from '@mui/material';
import { $generateHtmlFromNodes, $isRootTextContentEmpty, RichTextEditor } from '@talentmesh/rte';
import React, { useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { ClientFormValues } from '../Client';
import UIStrings from '../../../../Utils/UIStrings';
import useDebounce from '../../../../Hooks/UseDebounce';

interface ClientDescriptionProps {
    initialHtml?: string | null;
}

const ClientDescription = ({ initialHtml }: ClientDescriptionProps) => {
    const theme = useTheme();
    const {
        setValue,
        resetField,
        formState: { errors },
    } = useFormContext<ClientFormValues>();
    const { field } = useController<ClientFormValues>({ name: 'description' });
    const [description, setDescription] = useState('');
    const debouncedDescription = useDebounce(description, 50);

    useEffect(() => {
        setValue('description', debouncedDescription, {
            shouldValidate: true,
            shouldDirty: true,
        });
    }, [debouncedDescription, setValue, resetField]);

    return (
        <Stack spacing={1.25} useFlexGap>
            <Typography variant="h6" color={theme.palette.text.menuTitle}>
                {UIStrings.CompanyDescription}
            </Typography>
            <Typography variant="body2" color={theme.palette.text.menuTitle}>
                {UIStrings.CompanyDescriptionExplanation}
            </Typography>
            <Box
                position="relative"
                mt={theme.spacing(1.25)}
                padding={theme.spacing(2.5)}
                bgcolor={theme.palette.grey[100]}
                borderRadius={theme.shape.smallBorderRadius}
                borderColor={theme.palette.border.main}
            >
                <RichTextEditor
                    initialHtml={initialHtml!}
                    features={['headingStyle', 'bold', 'italic', 'ol', 'ul']}
                    onChange={(editorState, editor) => {
                        editorState.read(() => {
                            // quickcheck the text content if rte is truly empty, because empty rte is <p><br><p>
                            if ($isRootTextContentEmpty(editor.isComposing())) {
                                setDescription('');
                            } else {
                                setDescription($generateHtmlFromNodes(editor));
                            }
                        });
                    }}
                    placeholder={
                        <Box
                            position="absolute"
                            sx={{
                                inset: 0,
                                top: theme.spacing(12.2),
                                left: theme.spacing(4.125),
                                userSelect: 'none',
                                pointerEvents: 'none',
                            }}
                        >
                            <Typography variant="body1" color={theme.palette.text.disabled}>
                                {UIStrings.ABriefDescriptionOfYourCompany}
                            </Typography>
                        </Box>
                    }
                    contentEditableProps={{
                        onBlur: field.onBlur,
                        name: field.name,
                        sx: {
                            overflowY: 'auto',
                            flex: 1,
                            borderColor: errors.description ? theme.palette.error.main : 'inherit',
                        },
                    }}
                    toolbarProps={{ sx: { marginBottom: theme.spacing(1.25) } }}
                />
                {errors.description?.message && <FormHelperText error>{errors.description?.message}</FormHelperText>}
            </Box>
        </Stack>
    );
};

export default ClientDescription;
