import React from 'react';
import { Stack } from '@talentmesh/core';
import { PercentileScoreDTO, ScoreDTO } from '../../../../Models/CandidateOverview';
import { TalentScore } from '../../../../Models/TalentScore';
import UIStrings from '../../../../Utils/UIStrings';
import TestScore from './TestScore';
import { TestTypes } from '../../../../Models/Configuration';

interface DialogCardTestInfoProps {
    aptitudeScore?: PercentileScoreDTO | null;
    emotionalIntelligenceScore?: PercentileScoreDTO | null;
    personalityScore?: PercentileScoreDTO | null;
    skillsScore?: PercentileScoreDTO | null;
    talentScore?: TalentScore | null;
    experienceCriteriaScore?: ScoreDTO | null;
    testTypes: Array<TestTypes>;
    includesExperienceCriteria: boolean;
}

function DialogCardTestInfo({
    aptitudeScore,
    emotionalIntelligenceScore,
    personalityScore,
    skillsScore,
    talentScore,
    experienceCriteriaScore,
    testTypes,
    includesExperienceCriteria,
}: DialogCardTestInfoProps): JSX.Element {
    return (
        <Stack direction="row" sx={{ paddingLeft: 2.5, paddingRight: 2 }}>
            <TestScore
                label={UIStrings.TalentScore}
                scoreLevel={talentScore?.scoreLevel}
                scoreValue={talentScore?.talentScore}
                talentScore
            />
            {includesExperienceCriteria && (
                <TestScore
                    label={UIStrings.Experience}
                    scoreLevel={experienceCriteriaScore?.scoreLevel}
                    scoreValue={experienceCriteriaScore?.score}
                />
            )}
            {testTypes.includes(TestTypes.Personality) && (
                <TestScore
                    label={UIStrings.Personality}
                    scoreLevel={personalityScore?.percentileLevel}
                    scoreValue={personalityScore?.percentileScore}
                />
            )}
            {testTypes.includes(TestTypes.Aptitude) && (
                <TestScore
                    label={UIStrings.Aptitude}
                    scoreLevel={aptitudeScore?.percentileLevel}
                    scoreValue={aptitudeScore?.percentileScore}
                />
            )}
            {testTypes.includes(TestTypes.Skills) && (
                <TestScore
                    label={UIStrings.Skills}
                    scoreLevel={skillsScore?.percentileLevel}
                    scoreValue={skillsScore?.percentileScore}
                />
            )}
            {testTypes.includes(TestTypes.EmotionalIntelligence) && (
                <TestScore
                    label={UIStrings.EmotionalIntelligence}
                    scoreLevel={emotionalIntelligenceScore?.percentileLevel}
                    scoreValue={emotionalIntelligenceScore?.percentileScore}
                />
            )}
        </Stack>
    );
}

export default DialogCardTestInfo;
