import React from 'react';
import { Stack, TabContext, TabList, RoundButtonTab, Container } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';
import { useCandidatesOverviewContext } from '../Contexts/CandidatesOverviewContext';
import { CandidateTabs } from '../../../../Models/CandidateOverview';
import UIStrings from '../../../../Utils/UIStrings';
import CandidatesOverviewHeaderContent from './Header/CandidatesOverviewHeaderContent';
import CandidatesOverviewHeaderContentToolbar from './Header/CandidatesOverviewHeaderContentToolbar';

function CandidatesOverviewHeader(): JSX.Element {
    const theme = useTheme();
    const { tabValue, handleTabChange, totalOpen, totalHired, totalReject } = useCandidatesOverviewContext();

    const getCandidatesTabCountLabel = (value: UIStrings): string => {
        let statusCount;

        switch (value) {
            case UIStrings.Open:
                statusCount = totalOpen;
                break;
            case UIStrings.Hired:
                statusCount = totalHired;
                break;
            case UIStrings.Rejected:
                statusCount = totalReject;
                break;

            default:
                statusCount = 0;
                break;
        }

        return statusCount > 0 ? `${value} (${statusCount})` : `${value}`;
    };

    return (
        <Stack
            sx={{ backgroundColor: theme.palette.common.white, borderBottom: `1px solid ${theme.palette.border.main}` }}
        >
            <Container disableGutters>
                <Stack justifyContent="space-between" spacing={4} sx={{ paddingBlockStart: theme.spacing(3) }}>
                    <CandidatesOverviewHeaderContent />
                    <Stack justifyContent="space-between" alignItems="flex-end" direction="row">
                        <TabContext value={tabValue}>
                            <TabList onChange={handleTabChange}>
                                <RoundButtonTab
                                    label={getCandidatesTabCountLabel(UIStrings.Open)}
                                    value={CandidateTabs.Open}
                                />
                                <RoundButtonTab
                                    label={getCandidatesTabCountLabel(UIStrings.Hired)}
                                    value={CandidateTabs.Hired}
                                />
                                <RoundButtonTab
                                    label={getCandidatesTabCountLabel(UIStrings.Rejected)}
                                    value={CandidateTabs.Rejected}
                                />
                            </TabList>
                        </TabContext>
                        <CandidatesOverviewHeaderContentToolbar />
                    </Stack>
                </Stack>
            </Container>
        </Stack>
    );
}

export default CandidatesOverviewHeader;
