import React from 'react';
import { UndoToaster } from '@talentmesh/core';
import UIStrings from '../../../Utils/UIStrings';
import InviteForInterviewConfirm from './InviteForInterviewConfirm';
import InviteForInterviewDialog from './InviteForInterviewDialog';
import RejectDialog from './RejectDialog';
import { useActionDialogContext } from './Context/ActionDialogContext';
import HireDialog from './HireDialog';
import CongratsOnHireDialog from './CongratsOnHireDialog';

interface DialogsProps {
    assessmentId: string;
    assessmentName: string;
}

function Dialogs({ assessmentId, assessmentName }: DialogsProps): JSX.Element {
    const { alertTitle, alertMessage, alertStatus, openUndoToaster, setOpenUndoToaster, handleUndoActionAsync } =
        useActionDialogContext();

    const handleUndoMessageClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenUndoToaster(false);
    };

    return (
        <>
            <RejectDialog assessmentName={assessmentName} />
            <InviteForInterviewDialog assessmentName={assessmentName} />
            <InviteForInterviewConfirm />
            <HireDialog />
            <CongratsOnHireDialog assessmentId={assessmentId} />
            <UndoToaster
                status={alertStatus}
                autoHideDuration={5000}
                undoTitle={UIStrings.UNDO}
                alertTitle={alertTitle}
                alertMessage={alertMessage}
                open={openUndoToaster}
                close={handleUndoMessageClose}
                undo={async () => await handleUndoActionAsync(assessmentId)}
            />
        </>
    );
}

export default Dialogs;
