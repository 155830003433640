import React from 'react';
import * as yup from 'yup';
import YupValidationErrors from '../../../../../../Utils/YupValidationErrors';
import { extractTextWithNewLinesFromHtml } from '../../../../../Assessments/Modify/Steps/JobAdStep/JobAdStepUtils';

const noteValidationScheme = yup.object({
    noteRte: yup.string().test('TooLong', YupValidationErrors.RecruitersNoteInputLengthValidation, (value) => {
        const plainText = extractTextWithNewLinesFromHtml(value || '');
        return plainText.length <= 1000;
    }),
});

export default noteValidationScheme;
