import React from 'react';
import { Slide, SlideProps } from '@mui/material';
import { OptionsObject, SnackbarKey, SnackbarMessage, VariantType } from 'notistack';
import UIStrings from '../../../../Utils/UIStrings';
import SnackbarMessageContent, { SnackbarMessageStatus } from '../Components/SnackbarMessageContent';
import DownloadToaster from '../Components/Toasters/DownloadToaster';

export const getSnackbarOptions = (variant: VariantType, autoHide: boolean = false): OptionsObject => {
    const transitionLeft = (props: SlideProps) => {
        return <Slide {...props} direction="right" />;
    };
    return {
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        autoHideDuration: autoHide ? 5000 : null,
        variant,
        TransitionComponent: transitionLeft,
    };
};

export const getSnackbarMessage = (
    candidateName: string,
    status: SnackbarMessageStatus,
    key?: SnackbarKey,
    groupMode?: boolean,
): SnackbarMessage => {
    switch (status) {
        case 'pending':
            return (
                <SnackbarMessageContent
                    message={
                        groupMode
                            ? UIStrings.PreparingApplicantPresentationForDownload
                            : UIStrings.PdfDownloadPending(candidateName)
                    }
                    status={status}
                />
            );
        case 'warning':
            return <DownloadToaster id={key as string} message={UIStrings.PdfDownloadWarning} status="warning" />;
        case 'success':
            return (
                <DownloadToaster
                    id={key as string}
                    message={
                        groupMode
                            ? UIStrings.ApplicantsPresentationCreatedSuccessfully
                            : UIStrings.PdfDownloadSuccess(candidateName)
                    }
                    status="success"
                />
            );
        case 'error':
            return (
                <DownloadToaster
                    id={key as string}
                    message={
                        groupMode
                            ? UIStrings.SorryWeWereUnableToCreateApplicantsPresentation
                            : UIStrings.PdfDownloadFailure(candidateName)
                    }
                    status="error"
                />
            );
        default:
            throw new Error(`Unknown status: '${status}'`);
    }
};
