import { CandidateInviteDTO } from '../Users/CandidateInviteDTO';

export interface InvitedApplicantsDTO {
    candidates: Array<CandidateInviteDTO>;
    openLink: string;
}

export const DefaultInvitedApplicantsDTO: InvitedApplicantsDTO = {
    candidates: [],
    openLink: '',
};
