import React from 'react';
import { Grid } from '@talentmesh/core';
import { RecruitmentOverview } from '../../../../../Models/RecruitmentOverview';
import { HighestTalent } from '../../../../../Models/HighestTalent';
import UIStrings from '../../../../../Utils/UIStrings';
import HighestTalentScore from './HighestTalentScore';
import AssessmentInfo from './AssessmentInfo';
import BigStatusCounter from './components/BigStatusCounter';

const counterSize = 1.125;

function getTalentScoreLabel(highestTalent: HighestTalent): string {
    const { talentsCount, firstName, lastName } = highestTalent;
    let label: string;
    switch (talentsCount) {
        case 0:
            label = UIStrings.NA;
            break;
        case 1:
            label = `${firstName} ${lastName}`;
            break;
        default:
            label = `${talentsCount} ${UIStrings.ApplicantsLC}`;
            break;
    }

    return label;
}

interface RecruitmentListItemBodyProps {
    recruitment: RecruitmentOverview;
}

function RecruitmentListItemBody({ recruitment }: RecruitmentListItemBodyProps): JSX.Element {
    const { hasPersonality, hasAptitude, hasSkills, hasEmotionalIntelligence, statusesCount, highestTalent } =
        recruitment;

    const { invitedCount, invitedForInterviewCount, hiredCount, ongoingCount, openedCount, completedCount } =
        statusesCount;

    const gridItemSpacing = 1;

    return (
        <Grid container>
            <Grid item xs={1.2}>
                <BigStatusCounter status={UIStrings.TotalApplicants} count={invitedCount} />
            </Grid>
            <Grid sx={{ paddingLeft: gridItemSpacing }} item xs={7}>
                <AssessmentInfo
                    hasPersonality={hasPersonality}
                    hasAptitude={hasAptitude}
                    hasSkills={hasSkills}
                    hasEmotionalIntelligence={hasEmotionalIntelligence}
                    openedCount={openedCount}
                    ongoingCount={ongoingCount}
                    completedCount={completedCount}
                />
            </Grid>
            <Grid sx={{ paddingLeft: gridItemSpacing }} item xs={counterSize}>
                <BigStatusCounter status={UIStrings.Interviewing} count={invitedForInterviewCount} />
            </Grid>
            <Grid sx={{ paddingLeft: gridItemSpacing }} item xs={counterSize}>
                <BigStatusCounter status={UIStrings.Hired} count={hiredCount} />
            </Grid>
            <Grid sx={{ paddingLeft: gridItemSpacing }} item xs>
                <HighestTalentScore
                    score={highestTalent?.score?.percentileScore}
                    scoreLevel={highestTalent?.score?.percentileLevel}
                    scoreLabel={getTalentScoreLabel(highestTalent)}
                />
            </Grid>
        </Grid>
    );
}

export default RecruitmentListItemBody;
