import React, { useRef } from 'react';
import { LogoutOptions, useAuth0 } from '@auth0/auth0-react';
import { generatePath, useNavigate } from 'react-router';
import { NonInteractableButton, TruncatedTypography } from '@talentmesh/core';
import {
    ApartmentIcon,
    ChevronRightIcon,
    SettingsOutlinedIcon,
    LogoutOutlined,
    ChevronLeftIcon,
    KeyboardArrowUpIcon,
    KeyboardArrowDownIcon,
} from '@talentmesh/icons';
import { authProviderSettings, siteUrls } from '../../../AppSettings';
import { MenuViewItem, MenuViews } from '../../../Models/ProfileMenu';
import RoutePath from '../../../Routing/RoutePath';
import UIStrings from '../../../Utils/UIStrings';
import ProfileMenu from './ProfileMenu';
import useProfileMenuButton from './utils/useProfileMenuButton';
import useTalentMeshHistory from '../../../Routing/useTalentMeshHistory';
import { useCompanyContext } from '../../../Context/CompanyContext';
import { useUserContext } from '../../../Context/UserContext';

const ProfileMenuButton: React.FC = () => {
    const { memberships } = useUserContext();
    const { currentCompanyProfile, refreshCurrentCompanyProfile } = useCompanyContext();
    const { logout } = useAuth0();
    const navigate = useNavigate();
    const tmHistory = useTalentMeshHistory();
    const { anchorEl, handleClick, open, setAnchorEl, currentView, setCurrentView } = useProfileMenuButton();

    const logoutHandler = () => {
        const logoutOptions: LogoutOptions = {
            client_id: authProviderSettings.clientId,
            returnTo: siteUrls.homePageUrl,
        };
        logout(logoutOptions);
    };

    const settingsHandler = () => {
        tmHistory.push(RoutePath.AccountSettings);
    };

    const menuViewItems = useRef<MenuViewItem[]>([
        {
            viewId: MenuViews.Main,
            menuItems: [
                {
                    menuItemLabel: UIStrings.SwitchCompany,
                    icon: <ApartmentIcon color="secondary" />,
                    onClick: () => setCurrentView(MenuViews.Companies),
                    menuItemChildren: <ChevronRightIcon />,
                    preventClose: true,
                },
                {
                    menuItemLabel: UIStrings.Settings,
                    icon: <SettingsOutlinedIcon color="secondary" />,
                    onClick: settingsHandler,
                },
                {
                    menuItemLabel: UIStrings.Logout,
                    icon: <LogoutOutlined color="secondary" />,
                    onClick: logoutHandler,
                },
            ],
        },
        {
            viewId: MenuViews.Companies,
            menuItems: [
                {
                    menuItemLabel: UIStrings.Back,
                    icon: <ChevronLeftIcon color="secondary" />,
                    onClick: () => setCurrentView(MenuViews.Main),
                    preventClose: true,
                },
                ...memberships.map(({ companyId, companyName }) => ({
                    id: companyId,
                    menuItemLabel: companyName,
                    onClick: async () => {
                        await refreshCurrentCompanyProfile(companyId);
                        navigate(generatePath(RoutePath.CompanyRoot, { companyId }));
                    },
                    preventClose: false,
                    labelSx: {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    },
                })),
            ],
        },
    ]);

    return (
        <>
            <NonInteractableButton
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="text"
                color="secondary"
                disableElevation
                onClick={handleClick}
                endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                sx={{ backgroundColor: 'transparent' }}
                size="large"
            >
                <TruncatedTypography value={currentCompanyProfile.companyName} />
            </NonInteractableButton>
            <ProfileMenu
                open={open}
                menuViewItems={menuViewItems.current}
                currentView={currentView}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
            />
        </>
    );
};

export default ProfileMenuButton;
