import { MRT_SortingState } from 'material-react-table';
import { useCallback, useState } from 'react';
import { ApplicantRecruitmentOverviewResponse } from '../../../../../../Models/CandidateOverview';
import CandidateSortByEnum from '../../../Models/CandidateSortByEnum';
import { CandidatesOverviewContextType } from '../../CandidatesOverviewContext';

const useManualSorting = (sort: CandidatesOverviewContextType['sort']) => {
    const [sorting, setSortingState] = useState<MRT_SortingState>([]);

    const columnSortToEnum = useCallback(({ desc, id }: MRT_SortingState[0]) => {
        const e = CandidateSortByEnum;
        switch (id as keyof ApplicantRecruitmentOverviewResponse) {
            case 'talentScore':
                return desc ? e.TalentScoreDesc : e.TalentScoreAsc;
            case 'experienceCriteriaScore':
                return desc ? e.ExperienceCriteriaScoreDesc : e.ExperienceCriteriaScoreAsc;
            case 'aptitudePercentileScore':
                return desc ? e.AptitudeScoreDesc : e.AptitudeScoreAsc;
            case 'emotionalIntelligencePercentileScore':
                return desc ? e.EmotionalIntelligenceScoreDesc : e.EmotionalIntelligenceScoreAsc;
            case 'personalityPercentileScore':
                return desc ? e.PersonalityScoreDesc : e.PersonalityScoreAsc;
            case 'skillsPercentileScore':
                return desc ? e.SkillsScoreDesc : e.SkillsScoreAsc;
            default:
                throw new Error('invalid column');
        }
    }, []);

    const onSortingChange = useCallback<React.Dispatch<React.SetStateAction<MRT_SortingState>>>(
        (dispatcher) => {
            let newSortingState: MRT_SortingState;
            if (typeof dispatcher === 'function') {
                const result = dispatcher(sorting);
                setSortingState(result);
                newSortingState = result;
            } else {
                setSortingState(dispatcher);
                newSortingState = dispatcher;
            }

            sort(newSortingState.length ? columnSortToEnum(newSortingState[0]) : undefined);
        },
        [sorting, setSortingState],
    );

    return { sorting, onSortingChange };
};

export default useManualSorting;
