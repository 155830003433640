import { useTheme } from '@mui/material';
import { getColorByPercentileLevel, PercentileLevel, Typography } from '@talentmesh/core';
import React, { useMemo } from 'react';
import { PercentileScoreDTO as PSD, ScoreDTO } from '../../../../../../Models/CandidateOverview';
import { TalentScore } from '../../../../../../Models/TalentScore';

interface ScoreCellProps {
    scoring: TalentScore | PSD | ScoreDTO | null;
}

const isTalentScore = (value: unknown): value is TalentScore => typeof (value as TalentScore)?.talentScore === 'number';
const isPercentile = (value: unknown): value is PSD => typeof (value as PSD)?.percentileScore === 'number';
const isScore = (value: unknown): value is ScoreDTO => typeof (value as ScoreDTO)?.score === 'number';
type StandardScoring = [number, PercentileLevel];

const ScoreCell = ({ scoring }: ScoreCellProps) => {
    const theme = useTheme();
    const [score, level] = useMemo<StandardScoring>(() => {
        if (isTalentScore(scoring)) {
            return [scoring.talentScore, scoring.scoreLevel];
        } else if (isPercentile(scoring)) {
            return [scoring.percentileScore, scoring.percentileLevel];
        } else if (isScore(scoring)) {
            return [scoring.score, scoring.scoreLevel];
        }

        return [0, 'High'];
    }, [scoring]);

    return (
        <Typography
            variant="inherit"
            width="100%"
            align="center"
            color={scoring ? getColorByPercentileLevel(level, theme) : theme.palette.text.secondary}
        >
            {scoring ? score : '-'}
        </Typography>
    );
};

export default ScoreCell;
