import React from 'react';
import { LoadingButton } from '@talentmesh/core';
import UIStrings from '../../../../../Utils/UIStrings';
import { DialogEnum, useActionDialogContext } from '../../Context/ActionDialogContext';

function HireActions(): JSX.Element {
    const { hideDialog, hireApplicantAsync, isHireLoading } = useActionDialogContext();

    const closeHireDialog = () => {
        hideDialog(DialogEnum.Hire);
    };

    return (
        <LoadingButton
            fullWidth
            size="large"
            color="primary"
            variant="contained"
            loading={isHireLoading}
            onClick={async () => {
                await hireApplicantAsync();
                closeHireDialog();
            }}
        >
            {UIStrings.MarkAsHired}
        </LoadingButton>
    );
}

export default HireActions;
