import { GridColumnIcon } from '@mui/x-data-grid';
import { Button } from '@talentmesh/core';
import { MRT_ShowHideColumnsMenu as MRTShowHideColumnsMenu } from 'material-react-table';
import React, { useState } from 'react';
import { useCandidatesTableContext } from '../../Contexts/CandidatesTableContext/CandidatesTableContext';
import UIStrings from '../../../../../Utils/UIStrings';

const EditColumnsButton = () => {
    const table = useCandidatesTableContext();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleEditColClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <>
            <Button variant="outlined" size="small" onClick={handleEditColClick} startIcon={<GridColumnIcon />}>
                {UIStrings.EditColumns}
            </Button>
            <MRTShowHideColumnsMenu table={table} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
        </>
    );
};

export default EditColumnsButton;
