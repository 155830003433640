/* eslint-disable consistent-return */
import ContentTypes from '../ContentTypes';
import ErrorResponse from './Errors/ErrorResponse';
import handleHttpError from './Errors/handleHttpError';
import HttpError from './Errors/HttpError';

export interface HttpRequest {
    path: string;
    method?: string;
    body?: BodyInit;
    accessToken?: string;
    contentType?: undefined | string;
}

interface HttpResponse<T> {
    ok: boolean;
    body?: T;
}

export interface Strategy {
    body: BodyInit;
    contentType: string | undefined;
}

const setHeaders = (request: Request, config: HttpRequest): Request => {
    if (config.contentType) {
        request.headers.set('Content-Type', config.contentType);
    }
    if (config.accessToken) {
        request.headers.set('authorization', `bearer ${config.accessToken}`);
    }
    return request;
};

async function tryToJson(response: Response): Promise<any> {
    try {
        return await response.json();
    } catch (error) {
        return Promise.resolve(false);
    }
}

export const http = async <RESB>(config: HttpRequest): Promise<HttpResponse<RESB>> => {
    let request = new Request(config.path, {
        method: config.method || 'get',
        body: config.body,
    } as RequestInit);
    request = setHeaders(request, config);
    try {
        const response: Response = await fetch(request);
        if (response.ok) {
            const body: RESB = (await tryToJson(response)) || response.status === 200;
            return { ok: response.ok, body };
        } else {
            const text = await response.text();
            try {
                const errorResponse: ErrorResponse = JSON.parse(text);
                const error = handleHttpError(errorResponse);
                return Promise.reject(error);
            } catch (e) {
                return Promise.reject(new Error(text));
            }
        }
    } catch (error) {
        // catch network failures
        return Promise.reject(error);
    }
};

export const getFile = async (config: HttpRequest): Promise<Blob> => {
    const request = new Request(config.path, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            responseType: 'blob',
        },
    } as RequestInit);

    setHeaders(request, config);

    const response = await fetch(request);

    if (!response.ok) {
        throw new HttpError('Unable to get a file', response.status, response.type);
    }

    return response.blob();
};

export const postFile = async (config: HttpRequest, body?: BodyInit | null): Promise<Blob> => {
    const request = new Request(config.path, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            responseType: 'blob',
        },
        body,
    } as RequestInit);

    setHeaders(request, config);

    const response = await fetch(request);

    if (!response.ok) {
        throw new HttpError('Unable to get a file', response.status, response.type);
    }

    return response.blob();
};

export async function get<RESB>(config: HttpRequest): Promise<HttpResponse<RESB>> {
    const configuration = { ...config, method: 'get' };
    return await http<RESB>(configuration);
}

export async function put<RESB, RESP = RESB>(config: HttpRequest): Promise<HttpResponse<RESP>> {
    const configuration = { ...config, method: 'put' };
    return await http<RESP>(configuration);
}

export async function post<RESB>(config: HttpRequest): Promise<HttpResponse<RESB>> {
    const configuration = { ...config, method: 'post' };
    return await http<RESB>(configuration);
}

export async function patch<RESB>(config: HttpRequest): Promise<HttpResponse<RESB>> {
    const configuration = { ...config, method: 'PATCH' };
    return await http<RESB>(configuration);
}

export async function httpDelete<RESB>(config: HttpRequest): Promise<HttpResponse<RESB>> {
    const configuration = { ...config, method: 'delete' };
    return await http<RESB>(configuration);
}

export function toJson<T>(input: T): Strategy {
    return {
        body: JSON.stringify(input),
        contentType: ContentTypes.ApplicationJson,
    };
}
