import { ApplicantStatus } from '../../Models/CandidateOverview';
import ATSAction from '../../Models/ATSAction';

function isInviteForInterviewAllowed(candidateStatus: ApplicantStatus) {
    switch (candidateStatus) {
        case ApplicantStatus.Invited:
        case ApplicantStatus.Applied:
        case ApplicantStatus.Ongoing:
        case ApplicantStatus.Completed:
        case ApplicantStatus.Retaken:
            return true;
        default:
            return false;
    }
}

function isRejectAllowed(candidateStatus: ApplicantStatus) {
    switch (candidateStatus) {
        case ApplicantStatus.Invited:
        case ApplicantStatus.Applied:
        case ApplicantStatus.Ongoing:
        case ApplicantStatus.Completed:
        case ApplicantStatus.Retaken:
        case ApplicantStatus.InvitedForInterview:
            return true;
        default:
            return false;
    }
}

function isHireAllowed(candidateStatus: ApplicantStatus) {
    switch (candidateStatus) {
        case ApplicantStatus.Invited:
        case ApplicantStatus.Applied:
        case ApplicantStatus.Ongoing:
        case ApplicantStatus.Completed:
        case ApplicantStatus.Retaken:
        case ApplicantStatus.InvitedForInterview:
            return true;
        default:
            return false;
    }
}

function isATSActionAllowedInState(action: ATSAction, candidateStatus: ApplicantStatus): boolean {
    switch (action) {
        case ATSAction.InviteForInterview:
            return isInviteForInterviewAllowed(candidateStatus);
        case ATSAction.Reject:
            return isRejectAllowed(candidateStatus);
        case ATSAction.Hire:
            return isHireAllowed(candidateStatus);
        default:
            // by default an action is not allowed
            return false;
    }
}

export default isATSActionAllowedInState;
