import { UpdateRecruitmentDTO } from '../../../../../DTO/Assessments/UpdateRecruitmentDTO';
import { ClientResponse, DefaultClientResponse } from '../../../../../DTO/Clients/ClientResponse';
import ProcessingState from '../../../../../Models/ProcessingState';
import { JobAd, DefaultJobAd, mapJobAdModel2Dto } from '../../Models/JobAd';
import { JobAdGenerationError } from '../../Models/JobAdGenerationError';
import { JobDetails, DefaultJobDetails } from '../../Models/JobDetails';
import { mapJobDetailsModel2Dto } from '../../Steps/JobDetailsStep/JobDetailsStepUtils';

export interface EditRecruitmentContextState {
    recruitmentId: string;
    jobDetails: JobDetails;
    jobAd: JobAd;
    client: ClientResponse;
    regenerateAtStep2: false;
    showErrorDialog?: JobAdGenerationError | undefined;

    processingState: ProcessingState;
}

export const DefaultEditRecruitmentContextState: EditRecruitmentContextState = {
    recruitmentId: '',
    jobDetails: DefaultJobDetails,
    jobAd: DefaultJobAd,
    client: DefaultClientResponse,
    regenerateAtStep2: false,

    processingState: ProcessingState.Idle,
};

export function mapEditRecruitmentContextState2Dto(jobDetails: JobDetails, jobAd: JobAd): UpdateRecruitmentDTO {
    return {
        jobDetails: mapJobDetailsModel2Dto(jobDetails),
        jobAd: mapJobAdModel2Dto(jobAd),
    };
}
