import { useTheme } from '@mui/material';
import React from 'react';
import { generatePath, Link } from 'react-router-dom';
import useCompanyId from '../../../../../../Hooks/UseCompanyId';
import RoutePath from '../../../../../../Routing/RoutePath';
import { useCandidatesOverviewContext } from '../../CandidatesOverviewContext';
import TooltipTypography from '../../../Components/TooltipTypography';

interface ApplicantCellProps {
    id: string;
}

const ApplicantCell = ({ id, children }: React.PropsWithChildren<ApplicantCellProps>) => {
    const theme = useTheme();
    const companyId = useCompanyId();
    const { assessmentId } = useCandidatesOverviewContext();

    return (
        <TooltipTypography
            display="block"
            component={Link}
            to={generatePath(RoutePath.CandidateResult, { assessmentId, candidateId: id, companyId })}
            sx={{ textDecoration: 'none' }}
            color={theme.palette.text.highlight}
            fontWeight={theme.typography.fontWeightBold}
        >
            {children}
        </TooltipTypography>
    );
};

export default ApplicantCell;
