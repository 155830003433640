import { AssessmentStatus } from '../../Models/Configuration';
import { RecruiterTestDTO } from '../Tests/RecruiterTestDTO';
import { CandidateInviteDTO } from '../Users/CandidateInviteDTO';
import { ApplicantInformationDTO } from './ApplicantInformationDTO';
import { ExperienceCriteriaDTO } from './ExperienceCriteriaDTO';
import { DefaultJobDetailsDTO, JobDetailsDTO } from './JobDetailsDTO';

export interface RecruiterAssessmentDTO {
    id: string;
    clientId: string;
    jobDetails: JobDetailsDTO;
    applicantInformation: ApplicantInformationDTO[];
    createdAt: string;
    closedAt: string;
    lastViewedAt: string;
    status: AssessmentStatus;
    candidates: CandidateInviteDTO[];
    tests: RecruiterTestDTO[];
    jobAdId: string | null;
    experienceCriteria: ExperienceCriteriaDTO[];
}

export const DefaultRecruiterAssessmentDTO: RecruiterAssessmentDTO = {
    id: '',
    clientId: '',
    jobDetails: DefaultJobDetailsDTO,
    applicantInformation: [],
    createdAt: '',
    closedAt: '',
    lastViewedAt: '',
    status: 'NonPublic',
    candidates: [],
    tests: [],
    jobAdId: null,
    experienceCriteria: [],
};
