import { assessmentsApiUrl } from '../AppSettings';
import { CreateAssessmentDTO } from '../DTO/Assessments/CreateAssessmentDTO';
import { DefaultRecruiterAssessmentDTO, RecruiterAssessmentDTO } from '../DTO/Assessments/RecruiterAssessmentDTO';
import ApplicantsOverviewPageDTO from '../DTO/Candidates/ApplicantsOverviewPageDTO';
import { AdvancedFilterDTO } from '../DTO/Results/AdvancedFilterDTO';
import { DefaultVersionDto, VersionDto } from '../DTO/VersionDto';
import { AssessmentStatuses, mapAssessmentStatus } from '../Models/AssessmentData';
import { RecruitmentOverview } from '../Models/RecruitmentOverview';
import {
    CandidateAssessmentResults,
    DefaultCandidateAssessmentResults,
    mapCandidateAssessmentResultsDTO2Model,
} from '../Models/CandidateAssessmentResults';
import {
    ApplicantRecruitmentOverviewResponse,
    ApplicantStatus,
    fromApplicantsOverview,
} from '../Models/CandidateOverview';
import Page, { ApplicantsPage, EmptyApplicantsPage, EmptyRecruitmentOverviewPage } from '../Models/Page';
import CandidateFilterByEnum from '../Pages/Candidates/Overview/Models/CandidateFilterByEnum';
import CandidateSortByEnum from '../Pages/Candidates/Overview/Models/CandidateSortByEnum';
import getEnumKeyByEnumValue from '../Utils/getEnumKeyByEnumValue';
import { get, getFile, post, postFile, put, toJson } from '../Utils/HTTP/HttpUtils';
import { CandidateAssessmentResultsDTO } from '../DTO/Assessments/CandidateAssessmentResultsDTO';
import ClientBase from './ClientBase';
import { DefaultInvitedApplicantsDTO, InvitedApplicantsDTO } from '../DTO/Assessments/InvitedApplicantsDTO';
import { UpdateRecruitmentDTO } from '../DTO/Assessments/UpdateRecruitmentDTO';
import NoteDTO from '../DTO/Assessments/NoteDTO';
import { CloseRecruitmentDTO } from '../DTO/Assessments/CloseRecruitmentDTO';
import AdvancedFilterTypes from '../Models/AdvancedFilterTypes';
import {
    DefaultRecruitmentSpecificationDTO,
    RecruitmentSpecificationDTO,
} from '../DTO/Assessments/RecruitmentSpecificationDTO';

export default class AssessmentClient extends ClientBase {
    async getSpecificationAsync(recruitmentId: string): Promise<RecruitmentSpecificationDTO> {
        const response = await get<RecruitmentSpecificationDTO>({
            accessToken: await this.getAccessTokenSilently(),
            path: this.addHost(`recruitments/${recruitmentId}/specification`),
        });
        if (response.ok && response.body) {
            return response.body;
        }
        return DefaultRecruitmentSpecificationDTO;
    }

    async getAssessmentOverviewsAsync(
        offset: number,
        limit: number,
        statuses: AssessmentStatuses[],
        search: string,
        clientId: string,
    ): Promise<Page<RecruitmentOverview>> {
        const response = await get<Page<RecruitmentOverview>>({
            path: this.addHost(
                `recruitments/client/${clientId}/recruitments-overview/?${this.buildAssessmentQuery(
                    offset,
                    limit,
                    statuses,
                    search,
                )}`,
            ),
            accessToken: await this.getAccessTokenSilently(),
        });

        if (response.ok && response.body) {
            const data = (response.body.data ? response.body.data : []).map((d) => {
                return {
                    ...d,
                    status: mapAssessmentStatus(d.status),
                };
            });
            return {
                pagination: response.body.pagination,
                data,
            };
        } else {
            return EmptyRecruitmentOverviewPage;
        }
    }

    buildAssessmentQuery(offset: number, limit: number, statuses: AssessmentStatuses[], search: string): string {
        const query = new URLSearchParams({
            offset: offset.toString(),
            limit: limit.toString(),
        });

        statuses.map((status) => query.append('statuses', status));

        if (search?.length > 1) {
            query.append('search', search);
        }

        return query.toString();
    }

    async getApplicantsOverviewAsync(
        recruitmentId: string,
        offset: number,
        limit: number,
        filterBy: CandidateFilterByEnum[],
        search: string,
        status?: ApplicantStatus,
        sortBy?: CandidateSortByEnum,
        advancedFilters?: AdvancedFilterDTO[],
    ): Promise<ApplicantsPage<ApplicantRecruitmentOverviewResponse>> {
        const strategy = toJson(advancedFilters || []);
        const response = await post<ApplicantsOverviewPageDTO>({
            path: this.addHost(
                `recruitments/${recruitmentId}/applicants-overview?${this.buildApplicantOverviewQuery(
                    offset,
                    limit,
                    filterBy,
                    search,
                    status,
                    sortBy,
                )}`,
            ),
            accessToken: await this.getAccessTokenSilently(),
            ...strategy,
        });

        if (response.ok && response.body) {
            return fromApplicantsOverview(response.body);
        } else {
            return EmptyApplicantsPage;
        }
    }

    buildApplicantOverviewQuery(
        offset: number,
        limit: number,
        filterBys: CandidateFilterByEnum[],
        search: string,
        status?: ApplicantStatus,
        sortBy?: CandidateSortByEnum,
    ): string {
        const query = new URLSearchParams({
            offset: offset.toString(),
            limit: limit.toString(),
        });

        filterBys.forEach((filterBy) => {
            const filterKey = getEnumKeyByEnumValue(CandidateFilterByEnum, filterBy);
            query.append('filterBy', filterKey);
        });

        if (search?.length > 1) {
            query.append('search', search);
        }

        if (status) {
            const statusKey = getEnumKeyByEnumValue(ApplicantStatus, status);
            query.append('status', statusKey);
        }

        if (sortBy !== undefined) {
            query.append('sortBy', CandidateSortByEnum[sortBy]);
        }

        return query.toString();
    }

    async getAdvancedFilters(recruitmentId: string, filterType?: AdvancedFilterTypes): Promise<AdvancedFilterDTO[]> {
        const response = await get<AdvancedFilterDTO[]>({
            path: this.addHost(
                `recruitments/${recruitmentId}/applicants-overview/filters${this.buildAdvancedFiltersQuery(
                    filterType,
                )}`,
            ),
            accessToken: await this.getAccessTokenSilently(),
        });

        if (response.ok && response.body) {
            return response.body || [];
        } else {
            return [];
        }
    }

    buildAdvancedFiltersQuery(filterType?: AdvancedFilterTypes): string {
        if (!filterType) {
            return '';
        }

        return `?filterType=${filterType}`;
    }

    async getCandidatesAsync(recruitmentId: string): Promise<InvitedApplicantsDTO> {
        const response = await get<InvitedApplicantsDTO>({
            path: this.addHost(`recruitments/${recruitmentId}/applicants`),
            accessToken: await this.getAccessTokenSilently(),
        });

        if (response.ok && response.body) {
            return response.body || [];
        } else {
            return DefaultInvitedApplicantsDTO;
        }
    }

    async createAssessmentAsync(clientId: string, createDto: CreateAssessmentDTO): Promise<RecruiterAssessmentDTO> {
        const strategy = toJson(createDto);
        const response = await post<RecruiterAssessmentDTO>({
            path: this.addHost(`recruitments/client/${clientId}`),
            accessToken: await this.getAccessTokenSilently(),
            ...strategy,
        });

        if (response.ok && response.body) {
            return response.body;
        } else {
            return DefaultRecruiterAssessmentDTO;
        }
    }

    async updateRecruitmentAsync(recruitmentId: string, updateDto: UpdateRecruitmentDTO): Promise<void> {
        const strategy = toJson(updateDto);
        await put({
            path: this.addHost(`recruitments/${recruitmentId}`),
            accessToken: await this.getAccessTokenSilently(),
            ...strategy,
        });
    }

    async publishRecruitmentAsync(recruitmentId: string): Promise<void> {
        const path = this.addHost(`recruitments/${recruitmentId}/publish`);
        const accessToken = await this.getAccessTokenSilently();

        await post({
            path,
            accessToken,
        });
    }

    async closeAssessmentAsync(recruitmentId: string, rejectCandidates: boolean, sendEmail: boolean): Promise<void> {
        const path = this.addHost(`recruitments/${recruitmentId}/close`);
        const accessToken = await this.getAccessTokenSilently();
        const dto: CloseRecruitmentDTO = { rejectCandidates, sendEmail };
        const strategy = toJson(dto);

        await post({
            path,
            accessToken,
            ...strategy,
        });
    }

    async getCandidateResults(assessmentId: string, candidateId: string): Promise<CandidateAssessmentResults> {
        const response = await get<CandidateAssessmentResultsDTO>({
            path: this.addHost(`assessments/${assessmentId}/results?candidateId=${candidateId}`),
            accessToken: await this.getAccessTokenSilently(),
        });
        if (response.ok && response.body) {
            return mapCandidateAssessmentResultsDTO2Model(response.body);
        }

        return DefaultCandidateAssessmentResults;
    }

    async getCandidateResultsPdfReport(assessmentId: string, candidateId: string): Promise<Blob> {
        const encodedCandidateId = encodeURIComponent(candidateId);
        return getFile({
            path: `${assessmentsApiUrl}reports/assessments/${assessmentId}/results/pdf?candidateId=${encodedCandidateId}`,
            accessToken: await this.getAccessTokenSilently(),
        });
    }

    async getVersionAsync(): Promise<VersionDto> {
        const response = await get<VersionDto>({
            path: this.addHost(`version`),
            accessToken: await this.getAccessTokenSilently(),
        });
        if (response.ok && response.body) {
            return response.body;
        }
        return DefaultVersionDto;
    }

    async updateNoteAsync(recruitmentId: string, applicantId: string, note: NoteDTO): Promise<NoteDTO | undefined> {
        const payload = toJson(note);

        const response = await put<NoteDTO>({
            path: this.addHost(`assessments/${recruitmentId}/applicant/${applicantId}/note`),
            accessToken: await this.getAccessTokenSilently(),
            ...payload,
        });

        if (response.ok && response.body) {
            return response.body;
        }

        return undefined;
    }

    async getApplicantGroupReportResults(recruitmentId: string, applicantIds: Array<string>): Promise<Blob> {
        const payload = toJson({ applicantIds });

        return postFile(
            {
                path: `${assessmentsApiUrl}reports/recruitments/${recruitmentId}/results/group-report`,
                accessToken: await this.getAccessTokenSilently(),
            },
            payload.body,
        );
    }
}
