import React, { useRef } from 'react';
import { MenuViewItem, MenuViews } from '../../../Models/ProfileMenu';
import ProfileMenuHeader from './ProfileMenuHeader';
import ProfileMenuItem from './ProfileMenuItem';
import ProfilePopover from './ProfilePopover';

interface ProfileMenuProps {
    anchorEl: null | HTMLElement;
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
    open: boolean;
    menuViewItems: MenuViewItem[];
    currentView: MenuViews;
    onClose?: () => void;
}

const ProfileMenu = ({
    anchorEl,
    setAnchorEl,
    open,
    menuViewItems,
    currentView,
    onClose,
}: ProfileMenuProps): JSX.Element => {
    const isMainView = currentView === MenuViews.Main;
    const containerRef = useRef<HTMLDivElement>(null);

    const handleClose = (e: React.SyntheticEvent) => {
        e.stopPropagation();
        if (onClose) {
            onClose();
        }
        setAnchorEl(null);
    };

    return (
        <ProfilePopover
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            ref={containerRef}
            currentView={currentView}
        >
            {isMainView && <ProfileMenuHeader />}
            <div
                style={{
                    position: 'relative',
                }}
            >
                {open &&
                    containerRef.current &&
                    menuViewItems
                        .find((item) => item.viewId === currentView)
                        ?.menuItems.map(({ onClick, preventClose, menuItemLabel, id, ...props }, index) => (
                            <ProfileMenuItem
                                key={id || menuItemLabel}
                                id={id}
                                menuItemLabel={menuItemLabel}
                                onClick={(event) => {
                                    if (onClick) {
                                        onClick(event);
                                    }
                                    if (!preventClose) {
                                        handleClose(event);
                                    }
                                }}
                                sticky={index === 0 && currentView === MenuViews.Companies}
                                {...props}
                            />
                        ))}
            </div>
        </ProfilePopover>
    );
};

export default ProfileMenu;
