import { ApplicantStatus } from '../Models/CandidateOverview';

function isResendInvitationAllowed(applicantStatus: ApplicantStatus, completedAt?: string | null) {
    return (
        (completedAt === '' || completedAt === undefined || completedAt === null) &&
        applicantStatus !== ApplicantStatus.Completed &&
        applicantStatus !== ApplicantStatus.Hired &&
        applicantStatus !== ApplicantStatus.Rejected
    );
}

export default isResendInvitationAllowed;
