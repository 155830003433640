import { EducationDTO } from '../Assessments/EducationDTO';
import { LocationDetailsDTO } from '../Assessments/LocationDetailsDTO';
import { ExperienceDTO } from '../Users/ExperienceDTO';

export interface CandidateDTO {
    id: string;
    firstName: string | null; // can be null because the DTO comes from Auth0
    lastName: string | null; // can be null because the DTO comes from Auth0
    email: string;
    role: string;
    location: LocationDetailsDTO | null;
    isWillingToRelocate: boolean | null;
    experiences: ExperienceDTO[];
    educations: EducationDTO[];
}

export const DefaultCandidateDTO: CandidateDTO = {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    role: 'Candidate',
    location: null,
    isWillingToRelocate: null,
    experiences: [],
    educations: [],
};
