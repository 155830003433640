import { PaginationNav, Stack, Typography } from '@talentmesh/core';
import React, { useMemo } from 'react';
import UIStrings from '../../../../Utils/UIStrings';
import { useClientsOverviewContext } from '../../contexts/ClientsOverviewContext';

const ClientsOverviewPagination = () => {
    const {
        pagination: { limit, offset, totalCount },
        setPagination,
    } = useClientsOverviewContext();

    const paginationSizeInfo = useMemo<Parameters<typeof UIStrings.PaginationSizeInfo>>(() => {
        let currentSize = limit + offset;

        if (totalCount < currentSize) {
            currentSize = totalCount;
        }

        return [offset + 1, currentSize, totalCount];
    }, [limit, offset, totalCount]);

    const handleChange = (_: React.ChangeEvent<unknown>, value: number) => {
        const currentOffset = (value - 1) * limit;
        setPagination((p) => ({ ...p, offset: currentOffset }));
    };

    return (
        <Stack data-cy="clientsPagination" direction="row" spacing={2.5} justifyContent="center" alignItems="center">
            <PaginationNav
                page={(limit + offset) / limit}
                count={Math.ceil(totalCount / limit)}
                onChange={handleChange}
                boundaryCount={0}
                siblingCount={4}
                sx={{ marginLeft: 'auto' }}
            />
            <Typography variant="caption">{UIStrings.PaginationSizeInfo(...paginationSizeInfo)}</Typography>
        </Stack>
    );
};

export default ClientsOverviewPagination;
