import React from 'react';
import { MenuIconButton, MenuItemWithValueProps } from '@talentmesh/core';
import { MoreVertIcon, ResendInvitationIcon, StarFilledIcon, StarRemoveIcon } from '@talentmesh/icons';
import { useTheme } from '@mui/material/styles';
import isResendInvitationAllowed from '../../../../../../Utils/isResendInvitationAllowed';
import UIStrings from '../../../../../../Utils/UIStrings';
import { useActionDialogContext } from '../../../../../Dialogs/ATS/Context/ActionDialogContext';
import { useCandidatesOverviewContext } from '../../CandidatesOverviewContext';
import { ApplicantRecruitmentOverviewResponse } from '../../../../../../Models/CandidateOverview';

interface MoreActionsCellProps {
    candidate: ApplicantRecruitmentOverviewResponse;
}

function MoreActionsCell({ candidate }: MoreActionsCellProps): JSX.Element {
    const theme = useTheme();
    const { resendInvitationAsync } = useActionDialogContext();
    const { candidateId, isFavorite, candidateFirstName, candidateLastName, applicantStatus, completedAt } = candidate;

    const { saveFavoriteAsync, assessmentId } = useCandidatesOverviewContext();

    const saveFavoriteHandlerAsync = async () => {
        await saveFavoriteAsync(candidateId, !isFavorite);
    };

    const resendInvitationHandlerAsync = async () => {
        await resendInvitationAsync(assessmentId, candidateId, candidateFirstName, candidateLastName);
    };

    const resultsMoreMenuOptions: MenuItemWithValueProps[] = [
        {
            menuItemLabel: isFavorite ? UIStrings.RemoveFavorite : UIStrings.MarkFavorite,
            icon: isFavorite ? <StarRemoveIcon color="secondary" /> : <StarFilledIcon color="secondary" />,
            dense: true,
            onClick: saveFavoriteHandlerAsync,
        },
        {
            menuItemLabel: UIStrings.ResendInvitation,
            icon: <ResendInvitationIcon color="secondary" />,
            onClick: resendInvitationHandlerAsync,
            dense: true,
            disabled: !isResendInvitationAllowed(applicantStatus, completedAt),
        },
    ];

    return (
        <MenuIconButton
            menuItems={resultsMoreMenuOptions}
            icon={<MoreVertIcon />}
            buttonProps={{ sx: { mx: 'auto', width: theme.spacing(0.625), borderRadius: 0, padding: 0 } }}
        />
    );
}

export default MoreActionsCell;
