import { useTheme } from '@mui/material';
import { Box, PaginationNav, PaginationNavProps, Typography } from '@talentmesh/core';
import React, { useEffect, useMemo, useState } from 'react';
import UIStrings from '../../../../../Utils/UIStrings';
import { useCandidatesOverviewContext } from '../../Contexts/CandidatesOverviewContext';

const TableNav = () => {
    const theme = useTheme();
    const {
        pageNumber: zeroBasedPageNumbering,
        pageSize,
        totalCandidatesCount,
        handlePaginationModelChange,
    } = useCandidatesOverviewContext();
    const [pageNumber, setPageNumber] = useState(zeroBasedPageNumbering + 1);
    const offset = useMemo(() => (pageNumber - 1) * pageSize, [pageNumber, pageSize]);
    const currentSize = useMemo(
        () => (totalCandidatesCount < pageSize + offset ? totalCandidatesCount : pageSize + offset),
        [totalCandidatesCount, pageSize, offset],
    );

    const updatePageNumber = (page: number) => {
        setPageNumber(page);
        handlePaginationModelChange({ page: page - 1, pageSize });
    };

    useEffect(() => {
        // mui dataGrid automatically reset/go back one page if current page number exceeds total data count
        // this behaviour is replicated here
        if (totalCandidatesCount && offset + 1 > totalCandidatesCount) {
            const page = (totalCandidatesCount - pageSize) / pageSize + 1;
            updatePageNumber(page);
        }
    }, [offset, totalCandidatesCount, pageSize, setPageNumber]);

    const handlePaginationChange: PaginationNavProps['onChange'] = (_, page) => {
        updatePageNumber(page);
    };

    return (
        <Box
            display="flex"
            flexDirection="row"
            my={theme.spacing(2.5)}
            sx={{ placeContent: 'center', placeItems: 'center', gap: theme.spacing(2.5) }}
        >
            <PaginationNav
                page={pageNumber}
                count={Math.ceil(totalCandidatesCount / pageSize)}
                onChange={handlePaginationChange}
                boundaryCount={0}
            />
            <Typography variant="caption">
                {UIStrings.PaginationSizeInfo(
                    totalCandidatesCount > 0 ? offset + 1 : 0,
                    currentSize,
                    totalCandidatesCount,
                )}
            </Typography>
        </Box>
    );
};

export default TableNav;
