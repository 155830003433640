import { ApplicantStatus } from '../Models/CandidateOverview';

function isResetAssessmentAllowedState(candidateStatus: ApplicantStatus) {
    switch (candidateStatus) {
        case ApplicantStatus.Ongoing:
        case ApplicantStatus.Completed:
        case ApplicantStatus.InvitedForInterview:
        case ApplicantStatus.Interviewing:
        case ApplicantStatus.Rejected:
            return true;
        default:
            return false;
    }
}

export default isResetAssessmentAllowedState;
