import { createContext, useContext, SyntheticEvent } from 'react';
import { GridPaginationModel } from '@talentmesh/core';
import {
    ApplicantRecruitmentOverviewResponse,
    ApplicantStatus,
    CandidateTabs,
} from '../../../../Models/CandidateOverview';
import { TestTypes } from '../../../../Models/Configuration';
import { AdvancedFilter } from '../Models/AdvancedFilter';
import AdvancedFilterTypes from '../../../../Models/AdvancedFilterTypes';
import CandidateFilterByEnum from '../Models/CandidateFilterByEnum';
import CandidateSortByEnum from '../Models/CandidateSortByEnum';
import { AssessmentStatuses } from '../../../../Models/AssessmentData';

export interface CandidatesOverviewContextStateBase {
    assessmentName: string;
    assessmentStatus: AssessmentStatuses;
    clientId: string;
    candidates: ApplicantRecruitmentOverviewResponse[];
    selected: Array<ApplicantRecruitmentOverviewResponse>;
    loading: boolean;
    openFilter: boolean;
    testTypes: TestTypes[];
    totalCandidatesCount: number;
    pageSize: number;
    pageNumber: number;
    totalOpen: number;
    totalHired: number;
    totalReject: number;
    tabValue: CandidateTabs;
    jobCategoryName: string;
    jobFunctionName: string;
    advancedFilters: AdvancedFilter[];
    includesExperienceCriteria: boolean;
}

export interface CandidatesOverviewContextState extends CandidatesOverviewContextStateBase {
    assessmentStatus: AssessmentStatuses;
    status?: ApplicantStatus;
    filterBy: Array<CandidateFilterByEnum>;
    sortBy?: CandidateSortByEnum;
    search: string;
    tabValue: CandidateTabs;
    initialLoading: boolean;
}

export interface CandidatesOverviewContextType extends CandidatesOverviewContextStateBase {
    assessmentId: string;
    setSelected: (values: ApplicantRecruitmentOverviewResponse[]) => void;
    loadPageAsync: () => Promise<void>;
    sort: (value?: CandidateSortByEnum) => void;
    filter: (values: CandidateFilterByEnum[]) => void;
    searchByName: (name: string) => void;
    saveFavoriteAsync: (candidateId: string, isFavorite: boolean) => Promise<void>;
    handlePaginationModelChange: (model: GridPaginationModel) => void;
    filterBy: Array<CandidateFilterByEnum>;
    handleTabChange: (event: SyntheticEvent, value: CandidateTabs) => void;
    toggleOpenFilter: () => void;
    updateAdvancedFilter: (filter: AdvancedFilter) => void;
    resetAdvancedFilterByType: (filterType: AdvancedFilterTypes) => void;
    resetAdvancedFilter: () => void;
    search: string;
    groupReportProcessing: boolean;
    getApplicantGroupReportResults: () => Promise<void>;
}

export const CandidatesOverviewContext = createContext<CandidatesOverviewContextType | undefined>(undefined);

export function useCandidatesOverviewContext() {
    const context = useContext(CandidatesOverviewContext);
    if (!context) {
        throw new Error('useCandidatesOverviewContext must be used within the CandidatesOverviewContext.Provider');
    }
    return context;
}
